<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first" data-wizard-clickable="true">
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current" @click="setActiveTab('brief')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    API 文档简介
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('sign')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    签名算法
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('preoder')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    下单交易接口
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('orderCallback')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    订单回调
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('query')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    订单查询接口
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('withdraw')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    提现申请接口
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('withdrawCallback')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    提现回调
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('queryWithdraw')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    提现查询接口
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" @click="setActiveTab('queryBalance')">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <div class="wizard-title font-weight-bolder font-size-lg">
                    余额查询接口
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">

          <!-- API简介 -->
          <div class="row" v-if="activeTab == 'brief'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">API文档简介</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-75 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  " style="font-size:15px!important">
                  阅读对象: 使用 MgoogoPay 支付服务系统的技术架构师、研发工程师、系统运维工程师
                </div>
              </div>


              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  " style="font-size:13px!important">
                  更新时间: 2022-08-22 15:34:07
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="mt-8
                    text-dark-75 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                <b>网关地址</b>: <b-badge class="ml-5" variant="warning">https://api.mgoogo.com</b-badge>
              </h4>

              <h3 class="mt-10 mb-5 font-weight-bold text-dark">接入准备</h3>
              <div class="separator separator-dashed"></div>

              <h6 class="mt-8
                    text-dark-75 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                获取商户对接信息，在商户后台 <b> 商户中心 -> </b><b> 个人中心 -> </b><b> 商户信息 </b> 获取商户ID、AppId、商户私钥与平台公钥等信息
              </h6>

              <div class="mt-5">
                <img src="media/api_naviagtor.png" class="w-100" alt="" />
              </div>

              <h3 class="mt-10 mb-5 font-weight-bold text-dark">回调规则</h3>
              <div class="separator separator-dashed"></div>

              <h6 class="mt-8
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                商户实现支付回调与提现回调接口，订单有确切结果后，将回调给商户，如果回调失败，平台将最多重试三次
              </h6>
            </div>
            <!--end: Wizard-->
          </div>

          <!-- 签名算法 -->
          <div class="row" v-if="activeTab == 'sign'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">签名算法</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <b>说明: </b> <span class="ml-4">接口请求数据，采用 <b-badge class="mr-1" variant="danger">SHA256withRSA</b-badge>
                  算法进行签名；使用 <b-badge class="mr-1" variant="secondary">客户端私钥</b-badge>
                  对请求参数进行签名，使用<b-badge class="mr-1" variant="secondary">服务端公钥</b-badge>对响应结果进行验签</span>
              </div>

              <div class="d-flex flex-wrap my-4">
                <span> <b class="mr-4">指引: </b> 商户后台已上线签名在线调试工具，可自行使用在线调试工具进行参数签名与签名校验；菜单路径:<b> 商户中心 -> </b><b> 调试工具 ->
                  </b><b> </b>

                  <router-link :to="{ path: '/tools' }" target="_blank" tag="a" rel="opener">
                    <button class="
                      btn btn-success
                      font-weight-bold
                      text-uppercase
                      px-5
                      py-2
                      ml-4
                    ">
                      <v-icon>flaticon-paper-plane</v-icon>
                      打开调试工具
                    </button>
                  </router-link>
                </span>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">步骤一:</h4>
              <div>
                依次取出接口文档中标记为参与签名字段 的key、value; key 按 ASCII 码顺序排序，参数值需 要进行 URL 编码，并将二者拼接成 URL 参数形式。
              </div>

              <h5 class="my-5 text-dark-75">注意:</h5>
              <b-alert show variant="danger">当参与签名字段的 value
                为空字符串或null时，拼接为&key，不带=号；例如amount=10.00&extendInfo&payType=301 </b-alert>

              <h5 class="my-5 text-dark-75">请求示例:</h5>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{
                     document.sign.step1.request 
                    }}

                  </highlight-code>
                </div>
              </div>

              <h4 class="my-5 text-dark-75">参与签名字符串:</h4>

              <p class="w-100" style="word-wrap:break-word;!important">
                amount=120.00&appId=6gj2WsdBG7Lcpd7d&describe=%E6%A3%8B%E7%89%8C%E5%85%85%E5%80%BC&extendInfo&merchantId=1067&
                notifyUrl=http%3A%2F%2Flocalhost%3A9090%2Fcallback&outOrderId=1661173131844&payType=301&payerEmail=finley%40gmail.com
                &payerPhone=7027236862&product=%E6%B8%B8%E6%88%8F&returnUrl=http%3A%2F%2Fwww.baidu.com&timeExpire&timestamp=1661173131845
              </p>

              <h4 class="my-5 text-dark-75">步骤二:</h4>
              <div>
                使用SHA256withRSA签名算法，对参与签名字符串进行签名
              </div>

              <h4 class="my-5 text-dark-75">完整Java案例:</h4>
              <h5 class="my-5 text-dark-75">引入hutool 工具包</h5>


              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.sign.step2.pom  }}
                  </highlight-code>
                </div>
              </div>

              <h5 class="my-5 text-dark-75">签名</h5>


              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.sign.step2.sign  }}
                  </highlight-code>
                </div>
              </div>

              <h5 class="my-5 text-dark-75">验签</h5>
              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.sign.step2.checkSign  }}
                  </highlight-code>
                </div>
              </div>

            </div>
            <!--end: Wizard-->
          </div>

          <!-- 下单交易 -->
          <div class="row" v-if="activeTab == 'preoder'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">交易下单接口</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>

                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  接口路径:
                  <b-badge class="mr-1" variant="light">/trade/preorder</b-badge>
                </div>

                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                    <tr>
                      <td>merchantId</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户ID</td>
                      <td>1001</td>
                    </tr>
                    <tr>
                      <td>appId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户appId</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>请求时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>payType</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td>支付类型: 101 (微信支付); &nbsp;201 (支付宝); &nbsp;301 (印度Pay); &nbsp;401 (巴西Pay)</td>
                      <td>101</td>
                    </tr>
                    <tr>
                      <td>product</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商品名称</td>
                      <td>王者皮肤</td>
                    </tr>
                    <tr>
                      <td>describe</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商品描述</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>amount</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>交易金额: 单位/元 保留两位小数</td>
                      <td>888.00</td>
                    </tr>
                    <tr>
                      <td>payerPhone</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>付款人手机号</td>
                      <td>799889879</td>
                    </tr>
                    <tr>
                      <td>payerEmail</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>付款人邮箱</td>
                      <td>payer@gmail.com</td>
                    </tr>
                    <tr>
                      <td>returnUrl</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>前台跳转的url</td>
                      <td>http://www.baidu.com</td>
                    </tr>
                    <tr>
                      <td>notifyUrl</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>回调地址</td>
                      <td>http://www.baidu.com</td>
                    </tr>
                    <tr>
                      <td>extendInfo</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>扩展参数</td>
                      <td>{"json":"callback_content"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 class="my-5 text-dark-75">extendInfo(巴西代收) <font size="3" color="#F64E60">将该对象转换成JSON字符串后保存在extendInfo字段中</font></h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                     <tr>
                      <td>pixType</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>pix 类型：CPF, CNPJ, PHONE, EMAIL, EVP</td>
                      <td>CPF</td>
                    </tr>
                    <tr>
                      <td>pixAccount</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>pix账号</td>
                      <td>36991753831</td>
                    </tr>
                    <tr>
                      <td>cpfNumber</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>cpf税号</td>
                      <td>36991753831</td>
                    </tr>
                  
                  </tbody>
                </table>
              </div>

              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.preOrder.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共响应值 -->
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应状态码</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应结果描述</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>
                        商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>status</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 订单状态: 1、订单创建成功；2、订单创建失败；3、下单成功，等待支付；4、支付成功；5、支付超时；6、支付失败；7、支付异常</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>statusDesc</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>订单状态描述</td>
                      <td>订单创建成功</td>
                    </tr>
                    <tr>
                      <td>payUrl</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>支付url</td>
                      <td>http://www.baidu.com</td>
                    </tr>
                    <tr>
                      <td>extendInfo</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>扩展信息，原路返回</td>
                      <td>{'json':"callback"}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.preOrder.response  }}
                  </highlight-code>
                </div>
              </div>

              <div class="mt-10 separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">响应码列表：</h4>
              <div class="mb-5 separator separator-dashed"></div>

              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>响应码 </th>
                      <th>描述 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>成功</td>
                    </tr>
                    <tr>
                      <td>400</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>401</td>
                      <td>请求ip不在白名单中</td>
                    </tr>
                    <tr>
                      <td>403</td>
                      <td>签名错误</td>
                    </tr>
                    <tr>
                      <td>404</td>
                      <td>接口暂不支持</td>
                    </tr>
                    <tr>
                      <td>500</td>
                      <td>未知异常</td>
                    </tr>
                    <tr>
                      <td>600</td>
                      <td>商户不存在或被禁用</td>
                    </tr>

                    <tr>
                      <td>600</td>
                      <td>商户不存在或被禁用</td>
                    </tr>
                    <tr>
                      <td>602</td>
                      <td>商户未配置通道</td>
                    </tr>
                    <tr>
                      <td>603</td>
                      <td>支付类型未开通</td>
                    </tr>
                    <tr>
                      <td>605</td>
                      <td>订单金额不在允许范围内</td>
                    </tr>
                    <tr>
                      <td>610</td>
                      <td>订单已存在</td>
                    </tr>
                    <tr>
                      <td>611</td>
                      <td>下单异常</td>
                    </tr>
                    <tr>
                      <td>612</td>
                      <td>通道下单异常</td>
                    </tr>
                    <tr>
                      <td>613</td>
                      <td>通道下单失败</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--end: Wizard-->
          </div>

          <!-- 订单回调 -->
          <div class="row" v-if="activeTab == 'orderCallback'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">订单回调</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>



                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>13位unix时间戳</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 平台订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>status</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 订单状态: 1、订单创建成功；2、订单创建失败；3、下单成功，等待支付；4、支付成功；5、支付超时；6、支付失败；7、支付异常</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>statusDesc</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>订单状态描述</td>
                      <td>订单创建成功</td>
                    </tr>

                    <tr>
                      <td>amount</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>交易金额 (单位：元，保留两位小数)</td>
                      <td>888.00</td>
                    </tr>
                    <tr>
                      <td>tradeFee</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>交易手续费 (单位：元，保留两位小数)</td>
                      <td>0.80</td>
                    </tr>
                    <tr>
                      <td>payTime</td>
                      <td>long</td>
                      <td>否</td>
                      <td>是</td>
                      <td>支付时间（时间戳）</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.orderCallback.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>响应状态码 : 0 成功 非0 代表失败</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>响应结果描述</td>
                      <td>回调成功</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.orderCallback.response  }}
                  </highlight-code>
                </div>
              </div>

            </div>


            <!--end: Wizard-->
          </div>

          <!-- 查询订单 -->
          <div class="row" v-if="activeTab == 'query'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">订单查询接口</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>

                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  接口路径:
                  <b-badge class="mr-1" variant="light">/trade/order/query</b-badge>
                </div>

                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                    <tr>
                      <td>merchantId</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户ID</td>
                      <td>1001</td>
                    </tr>
                    <tr>
                      <td>appId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户appId</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>请求时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>否，与outOrderId 二选一</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>216602790667592811067</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>否，与orderId 二选一</td>
                      <td>是</td>
                      <td>商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.queryOrder.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共响应值 -->
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应状态码</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应结果描述</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>status</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 订单状态: 1、订单创建成功；2、订单创建失败；3、下单成功，等待支付；4、支付成功；5、支付超时；6、支付失败；7、支付异常</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>statusDesc</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>订单状态描述</td>
                      <td>订单创建成功</td>
                    </tr>

                    <tr>
                      <td>amount</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>交易金额 (单位：元，保留两位小数)</td>
                      <td>888.00</td>
                    </tr>
                    <tr>
                      <td>payTime</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>支付时间（时间戳）</td>
                      <td>1660891719556</td>
                    </tr>

                    <tr>
                      <td>extendInfo</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>扩展信息，原路返回</td>
                      <td>{'json':"callback"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.queryOrder.response  }}
                  </highlight-code>
                </div>
              </div>

              <div class="mt-10 separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">响应码列表：</h4>
              <div class="mb-5 separator separator-dashed"></div>

              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>响应码 </th>
                      <th>描述 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>成功</td>
                    </tr>
                    <tr>
                      <td>400</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>401</td>
                      <td>请求ip不在白名单中</td>
                    </tr>
                    <tr>
                      <td>403</td>
                      <td>签名错误</td>
                    </tr>
                    <tr>
                      <td>404</td>
                      <td>接口暂不支持</td>
                    </tr>
                    <tr>
                      <td>500</td>
                      <td>未知异常</td>
                    </tr>
                    <tr>
                      <td>600</td>
                      <td>商户不存在或被禁用</td>
                    </tr>
                    <tr>
                      <td>620</td>
                      <td>订单不存在</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>


            <!--end: Wizard-->
          </div>

          <!-- 提现申请接口 -->
          <div class="row" v-if="activeTab == 'withdraw'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">提现申请接口</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>

                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  接口路径:
                  <b-badge class="mr-1" variant="light">/trade/withdraw</b-badge>
                </div>

                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                    <tr>
                      <td>merchantId</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户ID</td>
                      <td>1001</td>
                    </tr>
                    <tr>
                      <td>appId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户appId</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>请求时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                     <tr>
                      <td>currency</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>交易币种，如：INR、BRL</td>
                      <td>默认为：INR</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>withdrawAmount</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>提现金额 (单位：元，保留两位小数)</td>
                      <td>100000.00</td>
                    </tr>
                    <tr>
                      <td>accountName</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>持卡人姓名</td>
                      <td>张三</td>
                    </tr>
                    <tr>
                      <td>cardNumber</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>银行卡账号</td>
                      <td>15496448984210</td>
                    </tr>
                    <tr>
                      <td>bankCode</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>银行代号</td>
                      <td>IFS</td>
                    </tr>
                    <tr>
                      <td>bankName</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>银行名称</td>
                      <td>印度国家银行</td>
                    </tr>

                    <tr>
                      <td>bankSubbranch</td>
                      <td>String</td>
                      <td>否</td>
                      <td>是</td>
                      <td>开户行名称</td>
                      <td>IFS</td>
                    </tr>

                    <tr>
                      <td>payeePhone</td>
                      <td>String</td>
                      <td>否</td>
                      <td>是</td>
                      <td>收款人手机号</td>
                      <td>70955625</td>
                    </tr>
                     <tr>
                      <td>extInfo</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>扩展信息（JSON格式）,详细参数请见扩展字段附录</td>
                      <td>例：{"cpfNumber":"12345678901","pixAccount":"36991753831","pixType":"CPF"}</td>
                    </tr>
                    <tr>
                      <td>notifyUrl</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>回调地址</td>
                      <td>http://www.baidu.com</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 class="my-5 text-dark-75">extInfo(巴西代付) <font size="3" color="#F64E60">将该对象转换成JSON字符串后保存在extInfo字段中</font></h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                     <tr>
                      <td>pixType</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>pix 类型：CPF, CNPJ, PHONE, EMAIL, EVP</td>
                      <td>CPF</td>
                    </tr>
                    <tr>
                      <td>pixAccount</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>pix账号</td>
                      <td>36991753831</td>
                    </tr>
                    <tr>
                      <td>cpfNumber</td>
                      <td>String</td>
                      <td>否</td>
                      <td>否</td>
                      <td>cpf税号</td>
                      <td>36991753831</td>
                    </tr>
                  
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.widthdraw.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共响应值 -->
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应状态码</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应结果描述</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>status</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 提现状态: 1、待审核；2、审核通过；3、渠道打款中；4、审核不通过；5、提现成功，已到账；6、提现失败；7、提现异常</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>statusDesc</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>提现状态描述</td>
                      <td>待审核</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.widthdraw.response  }}
                  </highlight-code>
                </div>
              </div>

              <div class="mt-10 separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">响应码列表：</h4>
              <div class="mb-5 separator separator-dashed"></div>

              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>响应码 </th>
                      <th>描述 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>成功</td>
                    </tr>
                    <tr>
                      <td>400</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>401</td>
                      <td>请求ip不在白名单中</td>
                    </tr>
                    <tr>
                      <td>403</td>
                      <td>签名错误</td>
                    </tr>
                    <tr>
                      <td>404</td>
                      <td>接口暂不支持</td>
                    </tr>
                    <tr>
                      <td>500</td>
                      <td>未知异常</td>
                    </tr>
                    <tr>
                      <td>600</td>
                      <td>商户不存在或被禁用</td>
                    </tr>

                    <tr>
                      <td>630</td>
                      <td>余额不足</td>
                    </tr>
                    <tr>
                      <td>631</td>
                      <td>提现通道未配置</td>
                    </tr>
                    <tr>
                      <td>632</td>
                      <td>通道代付失败</td>
                    </tr>
                    <tr>
                      <td>633</td>
                      <td>通道代付异常</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--end: Wizard-->
          </div>

          <!-- 提现回调接口 -->
          <div class="row" v-if="activeTab == 'withdrawCallback'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">提现回调</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>


                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>13位unix时间戳</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>status</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 提现状态: 1、待审核；2、审核通过；3、渠道打款中；4、审核不通过；5、提现成功，已到账；6、提现失败；7、提现异常</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>statusDesc</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>提现状态描述</td>
                      <td>审核通过</td>
                    </tr>
                    <tr>
                      <td>withdrawAmount</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>提现金额 (单位：元，保留两位小数)</td>
                      <td>19999.00</td>
                    </tr>
                    <tr>
                      <td>transferTime</td>
                      <td>long</td>
                      <td>否</td>
                      <td>是</td>
                      <td>支付时间（时间戳）</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.witdhrawCallback.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应状态码: 0 成功 非0 代表失败</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应结果描述</td>
                      <td>回调成功</td>
                    </tr>

                  </tbody>

                </table>
              </div>

              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.witdhrawCallback.response  }}
                  </highlight-code>
                </div>
              </div>

              <div class="mt-10 separator separator-dashed"></div>

            </div>

            <!--end: Wizard-->
          </div>


          <!-- 提现查询接口 -->
          <div class="row" v-if="activeTab == 'queryWithdraw'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">提现查询接口</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>

                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  接口路径:
                  <b-badge class="mr-1" variant="light">/trade/withdraw/query</b-badge>
                </div>

                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                    <tr>
                      <td>merchantId</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户ID</td>
                      <td>1001</td>
                    </tr>
                    <tr>
                      <td>appId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户appId</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>请求时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>否，与outOrderId 二选一</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>216602790667592811067</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>否，与orderId 二选一</td>
                      <td>是</td>
                      <td>商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.queryWidthdraw.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共响应值 -->
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应状态码</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应结果描述</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>orderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>平台订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>outOrderId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 商户订单号</td>
                      <td>2014072300007148</td>
                    </tr>
                    <tr>
                      <td>status</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td> 提现状态: 1、待审核；2、审核通过；3、渠道打款中；4、审核不通过；5、提现成功，已到账；6、提现失败；7、提现异常</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>statusDesc</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>提现状态描述</td>
                      <td>审核通过</td>
                    </tr>
                    <tr>
                      <td>withdrawAmount</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>提现金额 (单位：元，保留两位小数)</td>
                      <td>19999.00</td>
                    </tr>
                    <tr>
                      <td>transferTime</td>
                      <td>long</td>
                      <td>否</td>
                      <td>是</td>
                      <td>支付时间(时间戳)</td>
                      <td>1660891719556</td>
                    </tr>

                    <tr>
                      <td>bankName</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>银行名称</td>
                      <td>印度国家银行</td>
                    </tr>

                    <tr>
                      <td>accountName</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>持卡人姓名</td>
                      <td>张三</td>
                    </tr>

                    <tr>
                      <td>cardNumber</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>银行卡账号</td>
                      <td>印度国家银行</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.queryWidthdraw.response  }}
                  </highlight-code>
                </div>
              </div>

              <div class="mt-10 separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">响应码列表：</h4>
              <div class="mb-5 separator separator-dashed"></div>

              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>响应码 </th>
                      <th>描述 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>成功</td>
                    </tr>
                    <tr>
                      <td>400</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>401</td>
                      <td>请求ip不在白名单中</td>
                    </tr>
                    <tr>
                      <td>403</td>
                      <td>签名错误</td>
                    </tr>
                    <tr>
                      <td>404</td>
                      <td>接口暂不支持</td>
                    </tr>
                    <tr>
                      <td>500</td>
                      <td>未知异常</td>
                    </tr>
                    <tr>
                      <td>600</td>
                      <td>商户不存在或被禁用</td>
                    </tr>
                    <tr>
                      <td>634</td>
                      <td>提现订单不存在</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--end: Wizard-->
          </div>

          <!-- 余额查询接口 -->
          <div class="row" v-if="activeTab == 'queryBalance'">
            <div class="offset-xxl-1 col-xxl-10">
              <h2 class="mb-5 font-weight-bold text-dark">余额查询接口</h2>
              <div class="separator separator-dashed"></div>

              <div class="d-flex flex-wrap my-4">
                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  请求方式:
                  <b-badge class="mx-2" variant="success">POST</b-badge>
                </div>

                <div class="
                    text-dark-50 text-hover-primary
                    font-weight-bold
                    mr-lg-8 mr-5
                    mb-lg-0 mb-2
                  ">
                  接口路径:
                  <b-badge class="mr-1" variant="light">/trade/balance/query</b-badge>
                </div>

                <div class="text-dark-50 text-hover-primary font-weight-bold">
                  传参方式:
                  <b-badge class="mr-1" variant="light">
                    application/json</b-badge>
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">请求参数：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共请求参数 -->
                  <tbody>
                    <tr>
                      <td>merchantId</td>
                      <td>int</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户ID</td>
                      <td>1001</td>
                    </tr>
                    <tr>
                      <td>appId</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>商户appId</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>请求时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>

                </table>
              </div>
              <h4 class="my-5 text-dark-75">请求示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.queryBalance.request  }}
                  </highlight-code>
                </div>
              </div>

              <h4 class="mt-15 mb-5 text-dark-75">响应值：</h4>
              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>参数</th>
                      <th>类型</th>
                      <th>是否必填</th>
                      <th>参与签名</th>
                      <th>描述</th>
                      <th>示例</th>
                    </tr>
                  </thead>
                  <!-- 公共响应值 -->
                  <tbody>
                    <tr>
                      <td>code</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应状态码</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>message</td>
                      <td>String</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应结果描述</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>timestamp</td>
                      <td>long</td>
                      <td>是</td>
                      <td>是</td>
                      <td>响应时间戳，13位</td>
                      <td>1660891719556</td>
                    </tr>
                    <tr>
                      <td>sign</td>
                      <td>String</td>
                      <td>是</td>
                      <td>否</td>
                      <td>签名</td>
                      <td>7af7784e8036d21d9a298ca5e8818d7dd1216011</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>balance</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>余额 (单位：元，精确到分)</td>
                      <td>888.00</td>
                    </tr>

                    <tr>
                      <td>freezeBalance</td>
                      <td>Price</td>
                      <td>是</td>
                      <td>是</td>
                      <td>冻结余额 (单位：元，精确到分)</td>
                      <td>888.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 class="my-5 text-dark-75">响应示例：</h4>

              <div class="example-code">
                <div class="example-highlight">
                  <highlight-code lang="json" class="language-json">
                    {{  document.queryBalance.response  }}
                  </highlight-code>
                </div>
              </div>

              <div class="mt-10 separator separator-dashed"></div>

              <h4 class="my-5 text-dark-75">响应码列表：</h4>
              <div class="mb-5 separator separator-dashed"></div>

              <div>
                <table class="table table-vertical-center table-border-all">
                  <thead>
                    <tr class="text-left">
                      <th>响应码 </th>
                      <th>描述 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>成功</td>
                    </tr>
                    <tr>
                      <td>400</td>
                      <td>请求参数不合法</td>
                    </tr>
                    <tr>
                      <td>401</td>
                      <td>请求ip不在白名单中</td>
                    </tr>
                    <tr>
                      <td>403</td>
                      <td>签名错误</td>
                    </tr>
                    <tr>
                      <td>404</td>
                      <td>接口暂不支持</td>
                    </tr>
                    <tr>
                      <td>500</td>
                      <td>未知异常</td>
                    </tr>
                    <tr>
                      <td>600</td>
                      <td>商户不存在或被禁用</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.table-border-all {
  border-collapse: collapse;
  border-spacing: 0;
}

.table-border-all th {
  border: 1px solid #ebedf3 !important;
}

.table-border-all td {
  border: 1px solid #ebedf3 !important;
}

.example-code {
  padding: 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.42rem;
  max-height: 300px;
  overflow-y: scroll;
}

.example-highlight {
  padding: 1.75rem 1.5rem;
}
</style>

<script>
import {
  SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

export default {
  name: "Document",
  components: {},
  data() {
    return {
      activeTab: "brief",
      document: {
        sign: {
          step1: {
            request: { "amount": "120.00", "appId": "6gj2WsdBG7Lcpd7d", "describe": "棋牌充值", "merchantId": 1067, "notifyUrl": "http://localhost:9090/callback", "outOrderId": "1661173131844", "payType": 301, "payerEmail": "finley@gmail.com", "payerPhone": "7027236862", "product": "游戏", "returnUrl": "http://www.baidu.com", "sign": "s/u/YUx4J0Y78WszIpZv87l8KSR2DdZo6Wukj5S7jP/utNMS9JrOBNVRrwJ17PM2XV8Z8bItRnbtMDlW1VWdEQ==", "timestamp": 1661173131845 },
            signStr: "amount=120.00&appId=6gj2WsdBG7Lcpd7d&describe=%E6%A3%8B%E7%89%8C%E5%85%85%E5%80%BC&extendInfo&merchantId=1067&notifyUrl=http%3A%2F%2Flocalhost%3A9090%2Fcallback&outOrderId=1661173131844&payType=301&payerEmail=finley%40gmail.com&payerPhone=7027236862&product=%E6%B8%B8%E6%88%8F&returnUrl=http%3A%2F%2Fwww.baidu.com&timeExpire&timestamp=1661173131845"
          },
          step2: {
            pom: "<dependency> \n  <groupId>cn.hutool</groupId>   \n  <artifactId>hutool-all</artifactId>  \n  <version>5.5.7</version>  \n  </dependency>",
            sign: "Map<String,Object> paramsMap = new Hash Map<>(); \n  //  排序并转换成 URL 参数形式  \n String data = URLUtil.buildQuery(MapUtil.sort(paramsMap),Charset.forName('UTF-8')); \n // 签名 \n Sign sign = SecureUtil.sign(SignAlgorithm.SHA256withRSA, '(签名客户端私钥，请登录后台查看)'', null); \n byte[] bytes = sign.sign(signData.getBytes()); \n String signStr = Base64Encoder.encode(bytes);",
            checkSign: "Map<String,Object> paramsMap = new Hash Map<>(); \n  //  排序并转换成 URL 参数形式  \n String data = URLUtil.buildQuery(MapUtil.sort(paramsMap),Charset.forName('UTF-8')); \n // 签名 \n Sign verifySign  =  SecureUtil.sign(SignAlgorithm.SHA256withRSA,null,'服务器公钥验签（请登录平台查看)', null); \n byte[] signByte = Base64.decode(sign); \n boolean verify=verifySign.verify(data.getBytes(), signByte);;",

          }
        },
        preOrder: {
          request: {
            "amount": "120.00",
            "appId": "elJMdtpwts1sK25H",
            "describe": "棋牌充值",
            "merchantId": 1067,
            "notifyUrl": "http://localhost:9090/callback",
            "outOrderId": "1661158649946",
            "payType": 301,
            "payerEmail": "finley@gmail.com",
            "payerPhone": "7027236862",
            "product": "游戏",
            "returnUrl": "http://www.baidu.com",
            "sign": "J9HvlhpA8GcUQT1scCimBnHYxyO/S+H/HJok0XqlPPWFRjEp5RDE9p9n0KTmWLyxQS6ABsiddikk5+mClOCKeg==",
            "timestamp": 1661158649946
          },
          response: {
            "code": "0",
            "statusDesc": "通道下单成功，等待支付",
            "orderId": "216611604043686611067",
            "tradeFee": 3.5999999999999996,
            "sign": "hIPoYATi7hUzy4LVwuShJPqsbwTw8tD7xozZ4ZqBm0H0u2XxNAHhiIEehpOXlvyRlxh7D6cBL7scAu9DCp1Nog==",
            "payUrl": "https://www.joypays.in/gold-pay/qartPage/interfaceForwarding?tradeNo=A3317E18F0BFD5E7FEB2BA5A68F5A2A4",
            "message": "成功",
            "status": 3
          },
        },
        orderCallback: {
          request: {
            "status": 4,
            "statusDesc": "支付成功",
            "amount": 888.00,
            "tradeFee": 0.99,
            "payTime": "1660891719556",
            "orderId": "216611604043686611067",
            "outOrderId": "",
            "sign": "SS0aQNjJRUoYRM8qVbaKx13BX3aMIL69KVtzULXnwM5oYs6co9P132KGRbPz9dI1Lm47ZLJwA9iaOSACIgki8w==",
            "timestamp": 1661160509414
          },
          response: {
            "code": "0",
            "message": "成功",
          }
        },
        queryOrder: {
          request: {
            "appId": "6gj2WsdBG7Lcpd7d",
            "merchantId": 1067,
            "orderId": "216611604043686611067",
            "outOrderId": "",
            "sign": "SS0aQNjJRUoYRM8qVbaKx13BX3aMIL69KVtzULXnwM5oYs6co9P132KGRbPz9dI1Lm47ZLJwA9iaOSACIgki8w==",
            "timestamp": 1661160509414
          },
          response: {
            "code": "0",
            "statusDesc": "通道下单成功，等待支付",
            "orderId": "216611604043686611067",
            "sign": "lmshBhupY9+Ta3Hztf9+OM0eZj25puqGZCdb63WftE/G9FvQ8inORJrYQ+8IEe7jYL72dtKpEYm8bQ33/2LKUQ==",
            "message": "成功",
            "status": 3
          }
        },
        widthdraw: {
          request: {
            "accountName": "finley",
            "appId": "6gj2WsdBG7Lcpd7d",
            "bankCode": "100022",
            "bankName": "GoMoney",
            "bankSubbranch": "",
            "cardNumber": "5731189220",
            "merchantId": 1067,
            "notifyUrl": "http://localhost:9090/callback",
            "outOrderId": "1661161210321",
            "payeePhone": "7027236862",
            "sign": "mZFIFS5hvC6lMYihIuqhXWuzf5a5XMbbFe3RbpdIquLiqMBkMXZic5dTKJpMkVF4BFBCJH+PIRP7tPaThdcUoQ==",
            "timestamp": 1661161210321,
            "withdrawAmount": "20.00"
          },
          response: {
            "code": "0",
            "statusDesc": "提交成功，待审核",
            "orderId": "316611612495290701067",
            "outOrderId": "1661161210321",
            "sign": "XTDsDXn9ZYVj3/nHhBXG/SHkQT+0mThkJy/p2P0ih0jxlfzCZ/nwbhqsnuXh9qorvKB/LrtogGWBSDGcSEFsPw==",
            "message": "成功",
            "timestamp": 1661161250287,
            "status": 1
          }
        },
        witdhrawCallback: {
          request: {
            "orderId": "316611612495290701067",
            "outOrderId": "1661161210321",
            "withdrawAmount": 20.0,
            "transferTime": "1660891719556",
            "status": 1,
            "statusDesc": "提交成功，待审核",
            "sign": "ZHH8Y9hoO3F9GQHGuFQqH+/qvu5mm9IKKKa4FQ3w5lQp7J8gc5Zg+NSC+V7y4xWFd1cB/US2p9tN7ZVeiAQhBg==",
            "timestamp": 1661161477684
          },
          response: {
            "code": "0",
            "message": "成功",
          }
        },
        queryWidthdraw: {
          request: {
            "appId": "6gj2WsdBG7Lcpd7d",
            "merchantId": 1067,
            "orderId": "316611612495290701067",
            "outOrderId": "1661161210321",
            "sign": "ZHH8Y9hoO3F9GQHGuFQqH+/qvu5mm9IKKKa4FQ3w5lQp7J8gc5Zg+NSC+V7y4xWFd1cB/US2p9tN7ZVeiAQhBg==",
            "timestamp": 1661161477684
          },
          response: {
            "statusDesc": "提交成功，待审核",
            "orderId": "316611612495290701067",
            "accountName": "finley",
            "sign": "kNFqMNXlPQP0GCSignaFZiYFLFiW5S/tGmu0Bqj4mmdz692+FuoC6zXm1aSBu6fsiHM3JbaqumAzRkH1NxwcCA==",
            "bankName": "GoMoney",
            "outOrderId": "1661161210321",
            "withdrawAmount": 20.0,
            "cardNumber": "5731189220",
            "status": 1
          }
        },
        queryBalance: {
          request: {
            "appId": "6gj2WsdBG7Lcpd7d",
            "merchantId": 1067,
            "sign": "egh2MRxyo1GtlXc5a6F3I/PhAL7NjWco6VpH0bLbiEFNF761NcLbn00QTLbdgPOXPO2WyOSLdyHz3FdvS6QzRw==",
            "timestamp": 1661161795645
          },
          response: {
            "balance": 69.26,
            "sign": "tZWYXboxvzEbxafovpm129R6AxfBz+FfMTE0YdoLZaA1TH/+3P9BN+XBD0WRuXyN9rV/uTRZZpdesYVi8b+O8Q==",
            "freezeBalance": 112.0
          }
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach((hl) => {
        console.info(hl);
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });

    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Wizard",
      route: "wizard-1",
    },
    {
      title: "Wizard-2",
    },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function ( /*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function ( /*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    openTools() {

    }
  },
};
</script>